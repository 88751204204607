.home-content {
  max-width: 1300px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.home-content .home-title {
  margin-top: 32px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.home-content .content-title {
  margin: 16px 0 0 0;
  font-size: 1.875rem;
  line-height: 2.375rem;
  color: #101828;
  font-weight: 600;
}
.home-content .content-summary {
  margin: 4px 0 0 0;
  color: #667085;
  font-size: 1rem;
  line-height: 1.5rem;
}

.home-content .verification-list {
  margin: 32px 0;
}

table {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 100%;
  border-spacing: 0;
  font-family: "Inter", sans-serif;
}

thead tr {
  border-spacing: 0;
  background-color: #f9fafb;
  font-size: 0.875rem;
  line-height: 1.43rem;
  font-weight: 500;
  color: #667085;
  text-align: left;
  border-bottom: 1px solid #eaecf0;
}

.disabled {
  background-color: #fdfdfd;
  opacity: 0.75;
}

.disabled td {
  cursor: default !important;
}

.disabled .status {
  display: inline-block;
  margin-top: 8px;
  padding: 3px 10px;
  font-size: 11px;
  border: 1px solid #667085;
  border-radius: 100px;
}

th,
td {
  padding: 18px 0 18px 24px;
  border-bottom: 1px solid #eaecf0;
}

tr td {
  padding: 20px 0 20px 24px;
  color: #667085;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

td .flex img {
  width: 12px;
  height: 12px;
  margin-left: 6px;
}

.flex .avatar {
  width: 40px;
  height: 40px;
}

.flex .dataset {
  margin-left: 16px;
}

.dataset .name {
  color: #101828;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.dataset .address {
  color: #07939c;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.flex svg {
  margin-left: 6px;
}

.home-content {

}

.home-content .home-cta {
  margin-top: 100px;
  padding: 0 32px;
  display: flex;
  background-color: #f9fafb;
  border-radius: 8px;
}

.home-cta .cta-content {
  width: 608px;
  padding: 64px;
  font-family: "Inter", sans-serif;
  width: 50%;
}

.home-content img {
  width: 50%;
}

.cta-content h1 {
  color: #101828;
  margin: 0;
  font-size: 3rem;
  line-height: 3.75rem;
  letter-spacing: -0.96px;
  font-weight: 600;
}

.cta-content p {
  margin: 20px 0 0 0;
  color: #667085;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.cta-content .flex {
  margin-top: 40px;
}

.flex button {
  padding: 12px 20px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.flex button:first-child {
  border: 1px solid #07939c;
  color: #ffffff;
  background-color: #07939c;
  margin-right: 32px;
}

.flex button:last-child {
  border: 1px solid #d0d5dd;
  color: #475467;
  background: #ffffff;
}
