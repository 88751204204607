.Hero {
  height: 403px;
  position: relative;
  background: linear-gradient(117.62deg, #07939c -4.96%, #24b871 95.78%),
    linear-gradient(90deg, #7f56d9 0%, #9e77ed 100%),
    linear-gradient(90deg, #322272 0%, #5329ae 100%);
}

.LeftVector {
  position: absolute;
  left: 0;
  bottom: 0;
}

.RightVector {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 16px
}

.navlinks {
  padding: 0;
  margin: 0;
  display: flex;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navlinks li {
  list-style: none;
  margin-right: 36px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-family: "Noto Sans", sans-serif;
}

.navlinks li:last-child {
  margin: 0;
  padding: 10px 16px;
  border: 1px solid rgba(236, 253, 243, 0.5);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  backdrop-filter: blur(2px);
  border-radius: 100px;
}

.navlinks li a {
  color: white;
  text-decoration: none;
}

li > div {
  display: flex;
  align-items: center;
}

div > .link {
  margin-right: 8px;
}

.Hero .hero-content {
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-content p {
  margin: 8px 0 0 0;
  font-size: 1rem;
  line-height: 2.375rem;
  text-align: center;
  color: #f3fbf5;
  font-family: "DM Sans", sans-serif;
}

.hero-content .search-box {
  margin-top: 32px;
  position: relative;
}

.search-box input {
  box-sizing: border-box;
  padding: 14px 122px 14px 42px;
  width: 640px;
  min-height: 52px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background: #ffffff;
  border: none;
  border-radius: 100px;
}

input:focus,
select:focus {
  outline: none;
}

.search-box input::placeholder {
  position: static;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #98a2b3;
}

.search-box img {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

#basic-button {
  position: absolute;
  right: 0;
  top: 50%;
  width: 122px;
  font-size: 16px;
  line-height: 1.5;
  color: #101828;
  text-transform: none;
  transform: translateY(-50%);
}

.MuiMenu-paper {
  margin-top: 8px;
  margin-left: -48px;
}
